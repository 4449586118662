import { Component, OnInit } from '@angular/core';
import { ArtistService } from 'src/app/services/artist.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(private artist: ArtistService) { }

  ngOnInit() {
  }

  home() {
    this.artist.page = false;
    this.artist.home = true;
    this.artist.form = false;
  }

}
