import { Injectable } from '@angular/core';
import { artist } from '../../models/artist';
import { song } from '../../models/song';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ArtistComponent } from '../components/artist/artist.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {
  artistCollection: AngularFirestoreCollection<artist>;
  artist: Observable<artist[]>;

  page: boolean = false;
  home: boolean = true;
  form: boolean = false;

  // photoURL: string = null;

  constructor(public afs: AngularFirestore) {
    this.artistCollection = this.afs.collection('artists');

    // get's data from the database
    this.artist = this.artistCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
      const data = a.payload.doc.data() as artist;
      data.id = a.payload.doc.id;
      return data;
    })));
  }

  // get's artist data from the database
  getArtist() {
    return this.artist;
  }
}
