import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ArtistComponent } from './components/artist/artist.component';
import { ArtistService } from './services/artist.service';

import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { NavComponent } from './components/nav/nav.component';
import { SongComponent } from './components/song/song.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';

import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    ArtistComponent,
    NavComponent,
    SongComponent,
    AudioPlayerComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'cleanmusic'),
    AngularFirestoreModule,
    FormsModule
  ],
  providers: [ArtistService],
  bootstrap: [AppComponent]
})
export class AppModule { }
