// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyClkc0tDwM95Q9-uPhR9SrqxfzyJx8xdC8",
    authDomain: "cleanmusic-13c4d.firebaseapp.com",
    databaseURL: "https://cleanmusic-13c4d.firebaseio.com",
    projectId: "cleanmusic-13c4d",
    storageBucket: "cleanmusic-13c4d.appspot.com",
    messagingSenderId: "266165926410",
    appId: "1:266165926410:web:33b50b34baa300d4ae7df3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
