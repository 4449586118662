import { Component, OnInit } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { ArtistService } from 'src/app/services/artist.service';
import { artist } from 'src/models/artist';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { song } from 'src/models/song';
import { Form } from 'src/models/form';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {
  imgSrc: string = '/src/models/img/userimg.png';
  artists: artist[];
  songpage:boolean = false;
  artistPage: boolean = true;
  submited: boolean = false;

  currentArtist: any;
  song: Observable<song[]>;
  songCollection: AngularFirestoreCollection<song>;
  songs: song[];
  url: string;
  isClicked: boolean = false;
  Like: any[];

  likeImg: string = "../../../assets/heart-regular.svg";

  Form: Form = {
    artistName: '',
    songName: '',
  }
  
  FormCollection: AngularFirestoreCollection<Form>;

  constructor(private artistService: ArtistService, public afs: AngularFirestore) {}

  ngOnInit() {
    this.artistService.getArtist().subscribe(art => {
      if (!environment.production) {console.log(art);}
      this.artists = art;
    });
  }

  getSong() {
    return this.song;
  }

  artistpage() {
    this.artistPage = true;
    this.artistService.form = false;
    this.submited = false;
  }

  submitForm() {
    if (this.Form.artistName != '' && this.Form.songName != '') {
      // form database location
      this.FormCollection = this.afs.collection('submitions');

      // this adds the data to the database
      this.FormCollection.add(this.Form);
      this.artistService.form = false;
      this.submited = true;
    }
  }

  showForm() {
    this.artistService.form = true;
    this.artistService.home = false;
  }

  songPage(artistName:any) {
    this.artistService.page = true;
    this.artistService.home = false;
    // song database location
    this.songCollection = this.afs.collection(`songs/${artistName}/songinfo`);

    // get's all song objects from database
    this.song = this.songCollection.snapshotChanges().pipe(map(actions => actions.map(a => {
      const data = a.payload.doc.data() as song;
      data.id = a.payload.doc.id;
      return data;
    })));

    // get's the object from the database
    this.getSong().subscribe(song => {
      if (!environment.production) {console.log(song);}
      this.songs = song;
    })
  }

  like() {
    if (this.isClicked) {
      this.likeImg = "../../../assets/heart-solid.svg";
      this.isClicked = false;
    } else if (!this.isClicked) {
      this.likeImg = "../../../assets/heart-regular.svg";
      this.isClicked = true;
    }
  }
}
